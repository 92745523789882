<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    @close="close"
    width="570px"
  >
    <el-form
      ref="form"
      :inline="true"
      :model="form"
      :rules="roles"
      size="small"
      label-width="88px"
    >
      <el-form-item label="角色名称" prop="name">
        <el-input style="width: 420px" v-model="form.name" />
      </el-form-item>
      <el-form-item label="角色类型" prop="type">
        <el-select
          v-model="form.type"
          style="width: 420px"
          placeholder="请选择活动区域"
        >
          <el-option
            :label="item.label"
            v-for="(item, key) in typeList"
            :key="key"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述信息" prop="remark">
        <el-input
          style="width: 420px"
          type="textarea"
          v-model="form.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="close">取消</el-button>
      <el-button type="primary" @click="submit()">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      typeList: [
        { label: "平台管理员", value: 0 },
        { label: "运营人员", value: 1 },
        { label: "财务人员", value: 2 },
        { label: "门店管理人员", value: 3 },
        { label: "百货专柜负责人",value:4}
      ],
      form: {
        name: "", //	string	false	普通参数		角色名
        type: "", //角色类型（0 平台管理员，1 运营人员，2 财务人员3.门店管理人员）
        remark: "", //简介
      },
      roles: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        type: [
          { required: true, message: "请选择角色类型", trigger: "change" },
        ],
        url: [{ required: true, message: "请输入路由地址", trigger: "blur" }],
      },
      title: "创建角色",
    };
  },
  props: {
    selectItem: {},
  },
  mounted() {
    if (JSON.stringify(this.selectItem) != "{}") {
      let data = JSON.parse(JSON.stringify(this.selectItem));
      this.form = {
        name: data.roleName, //	string	false	普通参数		角色名
        type: data.roleType, //角色类型（0 平台管理员，1 运营人员，2 财务人员3.门店管理人员）
        remark: data.remark, //简介
        id: data.id,
      };
      this.title = "编辑角色";
      // console.log(this.form);
    }
  },
  methods: {
    // 选中图标
    selected(name) {
      this.form.icon = name;
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.form);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>
