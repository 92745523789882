<template>
  <div class="role">
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="searchBox flx-row ali-c">
          <el-input
            placeholder="输入角色名称"
            class="search-input"
            v-model="form.name"
            size="small"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="getList"
            size="small"
            >查询</el-button
          >
          <el-button
            icon="el-icon-refresh"
            class="ml-15"
            @click="reset"
            size="small"
            >重置</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            size="small"
            @click="openDialog()"
            >新增</el-button
          >
        </div>
      </el-col>
    </el-row>
    <div class="ListBody">
      <!-- 表格内容 -->
      <div class="listBox">
        <!-- 表格 -->
        <el-table
          class="mt-24 member-table"
          @row-click="tableSelect"
          :data="tableData"
        >
          <el-table-column label="角色名称" prop="roleName"></el-table-column>
          <el-table-column label="描述" prop="remark"></el-table-column>
          <el-table-column label="类型" prop="roleType">
            <template slot-scope="scope">
              <span v-if="scope.row.roleType == 0">平台管理员</span>
              <span v-if="scope.row.roleType == 1">运营人员</span>
              <span v-if="scope.row.roleType == 2">财务人员</span>
              <span v-if="scope.row.roleType == 3">门店管理人员</span>
              <span v-if="scope.row.roleType == 4">百货专柜负责人</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="flx-row ali-c">
                <span @click="openDialog(scope.row)" class="tip-text-edit">
                  <i class="el-icon-edit-outline"></i>
                  编辑
                </span>
                <span
                  class="ml-10 tip-text-delete"
                  @click="deleteData(scope.row.id)"
                >
                  <i class="el-icon-delete"></i>
                  删除
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :pageNum="form.pageNum"
          :pageSize="form.pageSize"
          :pageSizes="[10, 20, 30, 50, 100]"
          @changePage="getList"
          @syncPageData="syncPageData"
        ></Pagination>
      </div>
      <div class="treeBox">
        <div class="title">
          <div class="leftTitle">菜单分配 <span style="font-weight:600;font-size:18px;"> ({{selectObj.roleName}})</span> </div>
          <el-button
            type="primary"
            class="ml-15"
            size="small"
            @click="submitTree"
            >保存</el-button
          >
        </div>
        <div class="tree">
          <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            :default-expanded-keys="treeSelectArr"
            :default-checked-keys="treeSelectArr"
            ref="tree"
          >
           <!-- <span class="custom-tree-node" slot-scope="{ node, data }"> 
             <span>{{ node.label }}</span>
             <span>{{data.id}}</span>
           </span> -->
          </el-tree>
        </div>
      </div>
    </div>
    <my-add
      v-if="myAddType"
      :selectItem="selectItem"
      @submit="submit"
      @close="close"
    ></my-add>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import myAdd from "./mask/add";
import {
  sysRoleList,
  sysMenuTree,
  sysRolePut,
  sysRoleDel,
  sysRolePost,
  sysRoleDetail,
} from "@/api/system/role";
export default {
  components: { Pagination, myAdd },
  data() {
    return {
      data: [], //菜单树状图
      // 会员表格数据内容
      tableData: [],
      total: 0,
      defaultProps: {
        children: "children",
        label: "name"
      },
      form: {
        name: "",
        pageNum: 1,
        pageSize: 10,
      },
      selectItem: {},
      myAddType: false,
      treeSelectArr: [], //当前已经拥有的菜单id数组
      selectObj: {}, //当前选中的对象
    };
  },
  mounted() {
    this.getList();
    this.getTree();
  },
  methods: {
    //点击保存按钮
    submitTree() {
      //getCheckedKeys   若节点可被选择（即 show-checkbox 为 true），则返回目前被选中的节点的 key 所组成的数组
      //getHalfCheckedKeys  若节点可被选择（即 show-checkbox 为 true），则返回目前半选中的节点的 key 所组成的数组
      let idList = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());
        // console.log(idList)
      let idArrObj = idList.map((item) => {
        return { id: item };
      });
      // console.log(idArrObj)
      let data = {
        id: this.selectObj.id,
        name: this.selectObj.roleName,
        menuList: idArrObj,
        type: this.selectObj.roleType,
        remark: this.selectObj.remark,
      };
      // console.log(data);
      sysRolePut(data).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.selectItem = {};
      });
    },
    //点击表格某行  查某个角色权限列表
    tableSelect(row) {
      this.treeSelectArr.length = 0;
      this.selectObj = row;
      sysRoleDetail(this.selectObj.id).then((res) => {
        // console.log('111',res.data.body.menuList);
        this.getIdList(res.data.body.menuList);
        // console.log(this.treeSelectArr)
       this.$refs.tree.setCheckedKeys(this.treeSelectArr); 
      });
    },
    getIdList(Arr) {
      // console.log(this.treeSelectArr)
      if (Arr && Arr.length) {
        Arr.map((item) => {  
          if (item.children && item.children.length) {
            this.getIdList(item.children);
          }else {
            this.treeSelectArr.push(item.id);
          }
        });
      }
    },
    close() {
      this.selectItem = {};
      this.myAddType = false;
    },
    getList() {
      sysRoleList(this.form).then((res) => {
        this.tableData = res.data.body.list;
        if (this.tableData.length) {
          this.selectObj = this.tableData[0]
          this.tableSelect(this.tableData[0]);
        }
        this.total = res.data.body.total;
      });
    },
    getTree() {
      sysMenuTree().then((res) => {
        this.data = res.data.body;
        // console.log(res.data.body);
      });
    },
    reset() {
      this.form = {
        name: "",
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
    openDialog(item) {
      if (item) {
        this.selectItem = item;
      }
      this.myAddType = true;
    },
    submit(e) {
      // console.log(e);
      if (JSON.stringify(this.selectItem) === "{}") {
        //新增
        sysRolePost(e).then((res) => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.myAddType = false;
          this.selectItem = {};
          this.getList();
        });
      } else {
        //编辑
        sysRolePut(e).then((res) => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.myAddType = false;
          this.selectItem = {};
          this.getList();
        });
      }
    },
    submitForm() {},
    changePage(type) {
      this.form.pageSize = type.size;
      this.getList();
    },
    syncPageData(data) {
      this.form.pageNum = data.num;
      this.getList();
    },
    deleteData(id) {
      // console.log(id);
      sysRoleDel(id).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.getList();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.role {
  background: #fff;
  .searchBox {
    padding: 24px;
  }
  .ListBody {
    display: flex;
    .listBox {
      flex: 1;
      border: 1px solid #cdcdcd;
      margin: 0 20px;
      padding: 0 20px 20px;
      box-sizing: border-box;
      height: 100%;
    }
    .treeBox {
      width: 30%;
      border: 1px solid #cdcdcd;
      .title {
        display: flex;
        padding: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #cdcdcd;
        .leftTitle {
          flex: 1;
          line-height: 33px;
        }
      }
      .tree {
        padding: 30px;
        box-sizing: border-box;
      }
    }
  }
  .member-table {
    .circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }
  .tip-text-edit {
    color: #409eff;
    cursor: pointer;
  }
}
</style>
